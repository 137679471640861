/* Close iframe */
iframe {
  pointer-events: none;
}

@font-face {
  font-family: "NunitoSansBlack";
  src: local("NunitoSansBlack"),
    url("./assets/fonts/NunitoSans/NunitoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansBlackItalic";
  src: local("NunitoSansBlackItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-BlackItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansBold";
  src: local("NunitoSansBold"),
    url("./assets/fonts/NunitoSans/NunitoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansBoldItalic";
  src: local("NunitoSansBoldItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-BoldItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansExtraBold";
  src: local("NunitoSansExtraBold"),
    url("./assets/fonts/NunitoSans/NunitoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansExtraBoldItalic";
  src: local("NunitoSansExtraBoldItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-ExtraBoldItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansExtraLight";
  src: local("NunitoSansExtraLight"),
    url("./assets/fonts/NunitoSans/NunitoSans-ExtraLight.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansExtraLightItalic";
  src: local("NunitoSansExtraLightItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-ExtraLightItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansItalic";
  src: local("NunitoSansItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansLight";
  src: local("NunitoSansLight"),
    url("./assets/fonts/NunitoSans/NunitoSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansLightItalic";
  src: local("NunitoSansLightItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-LightItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "NunitoSansRegular";
  src: local("NunitoSansRegular"),
    url("./assets/fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansSemiBold";
  src: local("NunitoSansSemiBold"),
    url("./assets/fonts/NunitoSans/NunitoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "NunitoSansSemiBoldItalic";
  src: local("NunitoSansSemiBoldItalic"),
    url("./assets/fonts/NunitoSans/NunitoSans-SemiBoldItalic.ttf")
      format("truetype");
}

@font-face {
  font-family: "KozukaGothicProMedium";
  src: local("KozukaGothicProMedium"),
    url("./assets/fonts/kozuka-gothic-pro/KozGoPro-Medium.otf")
      format("truetype");
}

@font-face {
  font-family: "KozukaGothicProRegular";
  src: local("KozukaGothicProRegular"),
    url("./assets/fonts/kozuka-gothic-pro/KozGoPro-Regular.otf")
      format("truetype");
}

html {
  overflow: hidden;
  height: 100vh;
  height: var(--window-height) !important;
  width: var(--window-width) !important;
  font-size: 16px !important;
}

body {
  margin: 0;
  font-family: "NunitoSansRegular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  height: 100%;
  width: 100%;
}

#root,
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-layout {
  overflow-y: hidden !important;
}

/*custom drawer*/
.ant-drawer-header {
  background-color: #f7f7f8 !important;
  height: 64px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.ant-drawer-close {
  font-size: 18px !important;
}
.ant-drawer-title {
  font-size: 25px !important;
  font-weight: 600 !important;
}
.ground_btn {
  position: absolute;
  bottom: 40px;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  outline: 0;
  box-shadow: none !important;
}
.ant-input:focus {
  box-shadow: none !important;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none !important;
}
.ant-btn {
  transition: none !important;
  touch-action: none !important;
  box-shadow: none !important;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.65) !important;
  border-color: #d9d9d9 !important;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input:focus,
.ant-input:hover,
.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-select-selection:focus-within {
  border-color: #d9d9d9 !important;
}

@media (min-width: 425px) {
  html {
    font-size: 17px !important;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 18px !important;
  }
}
@media (min-width: 2540px) {
  html {
    font-size: 24px !important;
  }
}
